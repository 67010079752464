<template>
  <a v-if="link" class="dashbadge" :class="getActiveClass" :href="link">
    <span class="sectionlabel">{{ section }} </span>
    <span class="amount">{{ amount }}</span>
    <label>{{ label }}</label>
  </a>
  <div v-else class="dashbadge" :class="getActiveClass">
    <span class="sectionlabel">{{ section }} </span>
    <span class="amount">{{ amount }}</span>
    <label>{{ label }}</label>
  </div>
</template>
 
<script>
export default {
  name: "dashboardCount",
  props: {
    section: {
      type: String,
    },
    label: {
      type: String,
    },
    amount: {
      type: Number,
    },
    warningCount: {
      type: Number,
      default: 3,
    },
    errorCount: {
      type: Number,
      default: 10,
    },
    link: {
      type: String,
    },
  },
  computed: {
    getActiveClass: function () {
      if (this.amount >= this.errorCount) return "errorStatus";
      else if (this.amount >= this.warningCount) return "warningStatus";
      else return "normalStatus";
    },
  },
};
</script>

<style>
.dashbadge {
  border-radius: 20px;
  height: 120px;
  width: 120px;
  margin: 5px 10px;

  border: 2px solid rgba(255, 255, 255, 0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #eee;
  overflow: hidden;
  justify-content: space-between;
}

a.dashbadge:hover {
  color: inherit;
  opacity: 0.9;
  color: #eee;
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, 1);
}

.dashbadge label,
.dashbadge .sectionlabel {
  padding: 5px 0 5px 0;
  background: rgba(255, 255, 255, 0.2);
  font-size: 0.7rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.85);
  cursor: inherit;
}

.dashbadge label {
  font-size: 0.6rem;
  text-transform: none;
}

.dashbadge .sectionlabel {
}

.dashbadge .amount {
  padding: 10px 0;
  line-height: 2rem;
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
  cursor: inherit;
}

.dashbadge.normalStatus {
  background: #5cb85c;
}
.dashbadge.warningStatus {
  background: #f0ad4e;
}
.dashbadge.errorStatus {
  background: #d9534f;
}
</style>

